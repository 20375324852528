/*
* To change this license header, choose License Headers in Project Properties.
* To change this template file, choose Tools | Templates
* and open the template in the editor.
*/


jQuery(function(){
  $('.contact-message-prise-de-contact-form').each(function(){

    // let langue = $("html").attr("lang");
    // var vous, contact, projet;
    // console.log(langue);
    // switch (langue) {
    //   case 'en-x-simple':
    //     vous = 'About you';
    //     contact = 'How to contact you ?';
    //     projet = 'Your project in France ?';
    //     break;
    //   case 'es':
    //     vous = 'A proposito de usted';
    //     contact = '¿Cómo contactarle?';
    //     projet = '¿Su proyecto en Francia?';
    //     break;
    //   case 'zh-hans':
    //     vous = '关于你';
    //     contact = '怎么联系你？';
    //     projet = '你在法国的项目？';
    //     break;
    //   default:
    //     vous = 'À propos de vous';
    //     contact = 'Comment vous contacter ?';
    //     projet = 'Votre projet en France ?';
    //     break;
    // }


    // $('.field--name-field-civilite').before('<div class="wysiwyg-titreUnderline">' + vous + '</div>');
    // $('.field--name-field-telephone').before('<div class="wysiwyg-titreUnderline">' + contact + '</div>');
    // $('.field--name-field-raison-venue').before('<div class="wysiwyg-titreUnderline">' + projet + '</div>');

    $('.field--name-field-civilite').before( $( ".wysiwyg-titreUnderline.vous" ) );
    $('.field--name-field-telephone').before( $( ".wysiwyg-titreUnderline.contact" ) );
    $('.field--name-field-raison-venue').before( $( ".wysiwyg-titreUnderline.projet" ) );

    // <div class="Formulaire-label js-form-required form-required">

    let $dateNaissance = $("#edit-field-date-de-naissance-wrapper");
    $dateNaissance.addClass('row');
    // $(".js-form-item", $dateNaissance).addClass("col-sm-4");

    $("h4", $dateNaissance).wrap('<div class="Formulaire-label js-form-required form-required"></div>');

    if(typeof(offre) != 'undefined'){
      var val;
      switch(offre){
        case "15":
          val = "pack1";
          break;
        case "14":
          val = "pack2";
          break;
        default :
          val = "autre";
      }

      $("#edit-field-choix-offre").val(val);
    }





    //


  });
  $("#edit-field-raison-venue").change(updatePrecisez).trigger("change");
  function updatePrecisez(){
    var disp = $("#edit-field-raison-venue").val() == "autre";
    $("#edit-field-raison-autre-wrapper").css({display:(disp ? "block" : "none")});
  }

});
