/**
 *
 * Plugin jquery imageframe
 * @version : 1.2
 	* 1.1 - ajout scalemode proportionalInside / proportionalOutside
 	* 1.2 - ajout scalemode stretch / widthOnly / heightOnly et blur enlevé
 * @author: Marc Loyat dankastudio - mailto:marc@dankastudio.fr
 *
 * Plugin jquery qui permet de gérer le préchargement, le redimensionnement et le positionnement d'images dans une zone d'affichage
 *
 * Vocabulaire :
 	* Nous appelons ici "frame" la zone d'affichage dans laquelle l'image sera redimensionnée
	* Nous appelons "anchor" le point de l'image qui sera toujours visible dans la frame quelquesoit sa taille
		* cette anchor (ou ancre) correspond à l'intersection entre un positionnement vertical (haut, milieu ou bas de l'image)
		* et un positionnement horizontal (gauche, centre ou droite de l'image)
	* Nous appelons "opacity" le pourcentage de transparence qu'aura l'image une fois affichée
		* où 0 correspond à totalement transparent et 100 à totalement opaque
	* Nous appelons "thumbnail" l'image miniature affichée en attendant que l'image haute définition soit chargée et affichée
	* Nous appelons "scaleMode" le mode de redimensionnement choisi qui permettra de déterminer la largeur et la hauteur de l'image
		* où proportionalOutside (valeur par défaut) permet le redimensionnement homotétique avec une image qui peut déborder de la frame
		* où proportionalInside permet le redimensionnement homotétique avec une image qui est comprise dans la frame
		* où stretch permet d'adapter l'image à la frame
		* où widthOnly adapte la largeur à la frame et laisse la hauteur d'origine
		* où heightOnly adapte la hauteur à la frame et laisse la largeur d'origine

 *
 * Prérequis html
 	* Les images à redimensionner doivent être des éléments enfants de la frame
	* Il est plus que fortement recommandé d'indiquer la taille originale de l'image via les attributs html width et height
 *
 *
 * Prérequis css
 	* La frame doit avoir les attributs css suivants :
		* display : block|inline-block
		* position : relative|absolute|fixed
		* overflow : hidden
	* Il est recommandé que la frame ait les attributs css suivants :
		* background : black
	*
	* Par ailleurs la frame doit avoir une taille définie
	*
	* Les images doivent avoir les attributs css suivants :
		* display : block
		* position : absolute
	* Il est recommandé que les images aient l'attribut css suivant
		* opacity : 0

 * Exemple de html

	<div class="js-ImageFrame VisuelHeader">
		<img src="urldelimage.jpg" width="1200" height="960"/>
	</div>

 * Exemple de css associé

	.js-ImageFrame{
		overflow:hidden;
		position:relative;
		width:100%;
		background: #000;

	}
	.js-ImageFrame img{
		display:block;
		position:absolute;
		opacity:0;
	}
	.VisuelHeader{
		width:100%;
		height:80vh
	}


 * Attributs html :
 	* Sur les images :
		* @width - indique la largeur native en pixel de l'image (ne pas mettre px)
		* @height - indique la hauteur native en pixel de l'image (ne pas mettre px)
		* @data-anchor - définit l'anchor de l'image
			* valeurs possibles : TL|TC|TR|ML|MC|MR|BL|BC|BR
		* @data-opacity - définit l'opacité finale de l'image.
			* Une fois l'image chargée un fade est réalisé automatiquement pour afficher l'image
			* valeurs possible : un nombre entier entre 0 et 100
		* @data-src - url de l'image grand format de remplacement
			* Lorsque l'image à afficher est un grand format et doit être affichée rapidement,
			* il est recommandé de mettre l'url de cette image grand format dans l'attribut data-src
			* et de mettre l'url d'un thumbnail de très petite taille dans l'attribut src.
			* Les attributs de taille doivent être ceux de l'image grand format.
			* L'image thumbnail est mise à la taille et floutée, une fois le chargement de l'image
			* grand format effectué, elle remplace le thumbnail en douceur
		* @data-scaleMode - définit le scaleMode de l'image
			* valeurs possibles : proportionalOutside|proportionalInside|stretch|widthOnly|heightOnly
	*
	* Sur les frames :
		* @data-imageframe - permet de surcharger les paramètres d'imageframe (voir ci-dessous)
			* Les paramètres doivent être sous la forme d'une chaîne json correspondant au params js
 *
 *
 *
 * Paramètre JS
 	* L'appel de la fonction jquery peut se faire selon les modalités suivantes
		* $(".monelement").imageframe();
		* $(".monelement").imageframe(settings);
		* $(".monelement").imageframe(action);
	*
	* @settings:Object - permet de régler quelques options du plugin
		* liveResize:Boolean (true par défaut) - indique au plugin si la frame (et les images) doivent être redimensionnées lorsque
			* la fenêtre navigateur est redimensionnée. Si le paramètre est indiqué à false, il est possible de forcer
			* le resize via l'action "resize" (cf section @action ci-dessous)
		* fadingTime:int (800 par défaut) - durée par défaut du fade en millisecondes à la fin du chargement
		* opacity:int (100 par défaut) - opacity par défaut lorsqu'elle n'est pas indiquée dans un paramètre html
		* anchor:String ("MC" par défaut) - anchor par défaut lorsqu'elle n'est pas indiquée dans un paramètre html
	*
	* @action:String - indique au plugin une action à réaliser sur l'élément déjà initialisé
		* Valeurs attendues :
			* "resize" - peremet de lancer un redimensionnement manuel de l'image en fonciton de la taille de la frame
 *
 *
 * Initialisation automatique :
 	* Les élements html avec la classe "js-ImageFrame" seront initialisés automatiquement avec les paramètres par défaut
	* Si le paramètre data-imageframe de ces élements est présent et correctement formaté, les paramètres par défaut seront surchargés
 *

Quelques exemples :

Exemple 1 :

	<div class="js-ImageFrame VisuelHeader">
		<img src="chemin/vers/mon/thumbnail.jpg"
			width="1200"
			height="960"
			data-anchor="BL"
			data-opacity="80"
			data-src="chemin/vers/mon/image_grand_format.jpg"
			/>
	</div>

	 Cet élément sera initialisé automatiquement, le thumbnail très léger sera chargé et affiché flou très rapidement,
	 puis l'image grand format sera chargée et affichée en remplacement du thumbnail
	 Ces images seront automatiquement redimensionnées et affichées en opacité 80%
	 Lors du redimensionnement, le coin bas gauche de l'image sera toujours affiché quelque soit la taille de la frame



 Exemple 2 :

	<div class="js-ImageFrame VisuelHeader" data-imageframe='{"liveResize":false,"anchor":"TR","opacity":40}'>
		<img src="chemin/vers/mon/image_grand_format.jpg"
			width="1200"
			height="960"
			/>
	</div>

	Cet élément sera initialisé automatiquement, un resize de l'image sera effectué à la fin de son chargement (et donc,
	au moment de son affichage). L'image sera affichée à 40% d'opacité et son anchor sera le coin haut droit.
	Lorsque l'utilisateur changera la taille de la fenêtre,	l'image ne sera pas redimensionnée automatiquement.
	Ce redimensionnement pourra être effectué via l'appel suivant :

	$(".VisuelHeader").imageframe("resize")


 Exemple 3 :

	<div class="VisuelPouet">
		<img src="chemin/vers/mon/image_grand_format.jpg" width="1200" height="960"/>
	</div>

 	$(".VisuelPouet").imageframe({opacity:60, anchor:"MR", fadingTime:1600})

	Cet élément sera initialisé au moment de l'appel js ci-dessus et l'image sera affichée une fois chargée
	par un fade d'une durée de 1.6 seconde jusqu'à atteindre 60% d'opacité. Son anchor sera le point médian droite de l'image

 *
 */
(function($){

	$.fn.imageframe = function(){

		var options = null,
			action = null,
			arg
		;
		// arguments
		for(var i = 0, l = arguments.length; i<l; i++){
			arg = arguments[i];
			if (typeof(arg) == "object"){
				options = arg;
			}
			if(typeof(arg) == "string"){
				action = arg;
			}
		}
		// les actions
		// lance le resize à la mano de l'image
		var ACTION_RESIZE 			= "resize";
		// lance l'affichage de l'image
		var ACTION_SHOW 			= "show";// todo
		// lance l'affichage de l'image grand format en remplacement
		// du thumbnail
		var ACTION_REPLACE_THUMBNAIL	= "showHD";// todo

		// différents types d'alignement possibles
		var ANCHOR_TOP_LEFT 		= "TL";
		var ANCHOR_TOP_CENTER 		= "TC";
		var ANCHOR_TOP_RIGHT 		= "TR";
		var ANCHOR_MIDDLE_LEFT 		= "ML";
		var ANCHOR_MIDDLE_CENTER 	= "MC";
		var ANCHOR_MIDDLE_RIGHT 	= "MR";
		var ANCHOR_BOTTOM_LEFT 		= "BL";
		var ANCHOR_BOTTOM_CENTER 	= "BC";
		var ANCHOR_BOTTOM_RIGHT 	= "BR";

		// scaleMode
		var SCALE_MODE_PROPORTIONAL_OUTSIDE = "proportionalOutside";
		var SCALE_MODE_PROPORTIONAL_INSIDE 	= "proportionalInside";
		var SCALE_MODE_STRETCH 				= "stretch";
		var SCALE_MODE_WIDTH_ONLY 			= "widthOnly";
		var SCALE_MODE_HEIGHT_ONLY 			= "heightOnly";

		var jwin = $(window);


		function _resize(jframe, img){

			var frame = {w:jframe.width() , h:jframe.height()},
				jimg = $(img),
				w0 = jimg.attr("width"),
				h0 = jimg.attr("height"),
				opacity = _getOpacity(jimg),
				anchor = jimg.attr("data-anchor"),
				scaleMode = jimg.attr("data-scalemode")
			;

			// passage en capitales de l'alignement
			anchor = anchor.toUpperCase();

			var k, w, h ,x, y;
			// ratio de redimensionnement pour que l'image soit redimensionnée selon le scaleMode
			switch(scaleMode){
				case SCALE_MODE_PROPORTIONAL_INSIDE :
					k = Math.min(frame.w / w0, frame.h / h0);
					break;
				default :
					k = Math.max(frame.w / w0, frame.h / h0);
			}

			// position x et y en fonction du scaleMode
			switch(scaleMode){
				case SCALE_MODE_STRETCH :
					w = frame.w;
					h = frame.h;
					break;
				case SCALE_MODE_WIDTH_ONLY :
					w = frame.w;
					h = h0;
					break;
				case SCALE_MODE_HEIGHT_ONLY :
					w = w0;
					h = frame.h;
					break;
				default :
					w = w0 * k;
					h = h0 * k;
			}


			var vAlign = anchor.charAt(0),
				align = anchor.charAt(1)
			;
			// définition de l'alignement vertical
			switch(vAlign){
				case "B" :
					y = (frame.h - h) >> 0;
					break;
				case "T" :
					y = 0;
					break;
				default :
					y = (frame.h - h) >> 1;
			}

			// définition de l'alignement horizontal
			switch(align){
				case "R" :
					x = (frame.w - w) >> 0;
					break;
				case "L" :
					x = 0;
					break;
				default :
					x = (frame.w - w) >> 1;
			}

			// positionnement
			jimg.css({
				width:w + 1,
				height:h + 1,
				transform:"translate3D("+x+"px, "+y+"px, 0)",
				webkitTransform:"translate3D("+x+"px, "+y+"px, 0)"
			});
		}

		/**
		 * extraction de l'opacité de l'image et conversion en float [0,1]
		 */
		function _getOpacity(jel){

			var opacity = jel.attr("data-opacity");
			// bornage de l'opacité et passage de [0,100] à [0,1]
			opacity = isNaN(opacity) ? 100 : opacity < 0 ? 0 : opacity > 100 ? 100 : opacity;
			return opacity / 100;
		}

		/**
		 * fin du chargement de l'image grand format lorsqu'on a une procédure de préchargement
		 */
		function _gfOnLoad(gfImg, jimg, fadingTime){
			var jclone = jimg.clone();
			jclone.insertAfter(jimg);
			jimg.attr("src", gfImg.src);
			jclone.fadeTo(fadingTime, 0, function(){jclone.remove();});
		}


		/**
		 * quand il manque un attribut de dimension (width, height)
		 * on attend que l'image soit chargée pour récupérer ses dimensions et on réinitialise la frame
		 */
		function _autoReinit(jframe){

			$("img", jframe).each(function(){
				var jimg = $(this);
				jimg.one("load", function() {
					jimg.attr("width", this.width);
					jimg.attr("height", this.height);
					jframe.imageframe();
				}).each(function(){if(this.complete){jimg.load();}});
			});

		}

		if(action == ACTION_RESIZE){
			return this.each(function(){

				var jframe = $(this),
					jimgs = $("img", this)
				;

				jimgs.each(function(){
					_resize(jframe, this);
				});
			});
		}


		return this.each(function(){

			// paramètres par défaut surchargés par les params js s'il en est
			var settings = $.extend({
				liveResize:true,
				fadingTime:800,
				opacity: 100,
				anchor: ANCHOR_MIDDLE_CENTER,
				scaleMode: SCALE_MODE_PROPORTIONAL_OUTSIDE,
				autoShow: true,// todo
				autoReplaceThumbnail: true// todo
			}, options);


			var jframe = $(this),
				jimgs = $("img", this)
			;

			// si des paramètres sont passés via le html (chaîne json)
			var htmlsettings = jframe.attr("data-imageframe");
			if(htmlsettings){
				try{
					htmlsettings = JSON.parse(htmlsettings);
					htmlsettings = $.extend(options, htmlsettings);
					settings = $.extend(settings, htmlsettings);
				}catch(e){}

			}

			jimgs.each(function(){

				var img = this,
					jimg = $(this)
				;

				// valeur par défaut si non définies comme attendu
				if(!jimg.attr("width")){
					if(!this.width){return _autoReinit(jframe);}
					jimg.attr("width", this.width);
				}
				if(!jimg.attr("height")){
					if(!this.height){return _autoReinit(jframe);}
					jimg.attr("height", this.height);
				}
				if(!jimg.attr("data-opacity")){jimg.attr("data-opacity", settings.opacity);}
				if(!jimg.attr("data-anchor")){jimg.attr("data-anchor", settings.anchor);}
				if(!jimg.attr("data-scalemode")){jimg.attr("data-scalemode", settings.scaleMode);}

				// si l'image est une miniature et qu'un grand format est à précharger
				var gfURL = jimg.attr("data-src");
				if(gfURL){
					var gfImg = new Image();
					gfImg.onload = function(){_gfOnLoad(gfImg, jimg, settings.fadingTime);}
					gfImg.src = gfURL;
				}

				// resize
				if(settings.liveResize){
					jwin.resize(function(){
						_resize(jframe, img)
					});
				}
				_resize(jframe, this);


				// chargement
				jimg.one("load", function() {
					var jthis = $(this);
					jthis.fadeTo(settings.fadingTime, _getOpacity(jthis));
				}).each(function() {
					if(this.complete){jimg.load();}
				});

			})
		});
	};


	$(function(){$(".js-ImageFrame").imageframe()});

})(jQuery);
