$(function(){

    // Gestion menu
    var $Menu = $('.MenuOrdinateur, .MenuMobile'),
        $parents = $('ul > li > a, ul > li', $Menu),
        $item = $('ul > li > a, ul > li > span', $Menu),
        $subMenu = $('> ul ul', $Menu)
    ;

    // Click pour afficher les sous-menus
    $item.click(function(e){
        var thisSubMenu =  $(this).next('ul'),
            hasSubMenu =  thisSubMenu.length>0,
            $siblingsSubMenu = $(this).parent('li').siblings('li').find('ul'),
            $thisSubMenuItems = $('li', thisSubMenu),
            thisSubMenuHeight = 0
        ;

        // Si il y a un sous-menu, tu tulutu uu.
        if(hasSubMenu){

            $thisSubMenuItems.each(function(i, el){
                thisSubMenuHeight += el.offsetHeight;
            });
            var $this = $(this),
                $parent = $this.parent()
            ;

            if($parent.hasClass('isOpen')){
                $this.removeClass('isActive')
                $parent.removeClass('isOpen')
                thisSubMenu.removeClass('isOpen');

                // Si on est sur le menu principal
                if( $this.parents('.MenuOrdinateur').length && thisSubMenu.hasClass('level-3') ) {
                    thisSubMenu.height(0);
                }
            }else{
                $siblingsSubMenu.removeClass('isOpen');
                thisSubMenu.addClass('isOpen');
                $item.removeClass('isActive');
                $parents.removeClass('isOpen');
                $this.addClass('isActive');
                $parent.addClass('isOpen');

                // Si on est sur le menu principal
                if( $this.parents('.MenuOrdinateur').length && thisSubMenu.hasClass('level-3') ) {
                    $siblingsSubMenu.height(0);
                    thisSubMenu.height(thisSubMenuHeight);
                }
            }

            // Si sous-menu, on ne passe pas dans le lien, tin tintinlin tintin
            if(window.matchMedia("(max-width: 930px)").matches){
              return false;
            }
        }
    });

});
