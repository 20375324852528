
(function($){

	$.fn.videoframe = function(options){


		var settings = $.extend({

		}, options);




		return this.each(function(){

			var jthis = $(this),
				youtubeID = jthis.attr("data-id"),
				id = 'player-'+youtubeID
			;

			jthis.attr("id", id);
			
			var player = new YT.Player(id, {
				height: '100%',
				width: '100%',
				videoId: youtubeID,
				playerVars:{
					controls: 0,
//					modestbranding:1,
					rel:0,
					showinfo:0
				},
				events: {
					'onReady': onPlayerReady,
					'onStateChange': onPlayerStateChange
				}
			});
			function onPlayerReady(event) {
//				event.target.playVideo();
			}

			var done = false;
			function onPlayerStateChange(event) {
				return;
				if (event.data == YT.PlayerState.PLAYING && !done) {
//					setTimeout(stopVideo, 6000);
					stopVideo();
					done = true;
				}
			}
			function stopVideo() {
				player.stopVideo();
			}

		});

	};

	if($("script[src='https://www.youtube.com/iframe_api']").length == 0){

		var tag = document.createElement('script');
		tag.src = "https://www.youtube.com/iframe_api";

		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	}

	detectYT();
	
	function detectYT(){
		if(typeof(YT) == "undefined" || typeof(YT.Player) == "undefined" ){
			return setTimeout(detectYT, 80);
		}
		$(".js-VideoFrame").videoframe();
	}
	
})(jQuery);
