import Headroom from "headroom.js";
import Masonry from 'masonry-layout';
$(document).ready(function () {

    //------------------------------------------
    // Bouton menu
    //------------------------------------------
    var jBtn = $('.SiteHeader-Change'),
    jMenu = $('.MenuMobile'),
    jbody = $('body')
    ;

    jBtn.click(function() {
        jMenu.toggleClass('isOpen');
        if(jMenu.hasClass('isOpen')) {
            jbody.css('overflow','hidden');
            jBtn.children('em').removeClass('icon-navicon').addClass('icon-close');
        } else {
            jbody.css('overflow','initial');
            jBtn.children('em').removeClass('icon-close').addClass('icon-navicon');
        }
        return false;
    });


    var myElement = document.querySelector(".SiteHeader");
    var headroom = new Headroom(myElement);
    headroom.init();


    //------------------------------------------
    // Skip links
    //------------------------------------------
    var jSkip = $('#skip-link'),
    jLinks = $('a', jSkip)
    ;

    jLinks.blur(function () {
        jSkip.removeClass('focus');
    })
    .focus(function () {
        jSkip.addClass('focus');
    });



    //------------------------------------------
    // Dotdotdot / Cut des textes trop longs avec "..."
    //------------------------------------------

    $('.dotdotdot').dotdotdot({
        ellipsis: '...',
        wrap: 'letter',
        fallbackToLetter: true,
        watch: true,
        tolerance: 0,
        callback: function (isTruncated, orgContent) {},
        lastCharacter: {
            remove: [' ', ',', ';', '.', '!', '?'],
            noEllipsis: []
        }
    });



    jQuery('.Slider').each(function () {
        var jSlider = $('.Slider'),
        jControls = $('.Slider-controls a'),
        jContainer = $('.Slider-container'),
        jImg = $('.Slider-img:not(.background)'),
        current = 1,
        total = $('.Slider-img:not(.background)').length - 1
        ;
        if(total == 0){return;}
        animate();
        // Au click
        jControls.click(function () {
            if ($(this).hasClass('left')) {
                change('left');
            } else {
                change('right');
            }
            return false;
        });
        // Change de slide courante
        function change(position) {
            if (position == "left") {
                current = (current == 0) ? total : current - 1;
            } else {
                current = (current == total) ? 0 : current + 1;
            }
            animate();
        }
        // Anime le container pour changer de slide
        function animate() {
            jImg.removeClass('active');
            jImg.eq(current).addClass('active');
            TweenLite.to(jContainer, 0.8, {x: -current * jImg.width(), ease: Power4.easeOut});
        }
    });



    jQuery('.Home-Temoignages-Slider').each(function(){
        var jSlider = $('.Home-Temoignages-Slider'),
        jControls = $('.Home-Temoignages-controls a'),
        jContainer = $('.Home-Temoignages-Slider-container'),
        jBullets = $('.Home-Temoignages-bullets .bullet'),
        jImg = $('.Home-Temoignages-Slider-content'),
        current = 0,
        total = $('.Home-Temoignages-Slider-content').length -1
        ;
        animateTemoignage();
        // Au click
        jControls.click(function(){
            if($(this).hasClass('top')){
                changeTemoignage('top');
            } else {
                changeTemoignage('bottom');
            }
            return false;
        });

        function changeBullet(nombre) {
            $(".Home-Temoignages-bullets .bullet").removeClass('isActive');
            $(".Home-Temoignages-bullets .bullet[data-current='" + nombre + "']").addClass('isActive');
        }


        // Change de slide courante
        function changeTemoignage(position) {
            if (position == "top") {
                current = (current == 0) ? total : current - 1;
            } else {
                current = (current == total) ? 0 : current + 1;
            }
            animateTemoignage();
        }
        // Anime le container pour changer de slide
        function animateTemoignage() {
            changeBullet(current);
            TweenLite.to(jContainer, 0.8, {y: -current * 350, ease: Power4.easeOut});
        }
    });







    jQuery('.js-scrollTo').on('click', function (e) {
        e.preventDefault();
        var ancre = $(this).attr('href');
        jQuery('html, body').animate({scrollTop: $(ancre).offset().top}, 500);
        return false;
    });




    jQuery('#footerInputNewsletter').keyup(function() {
        jQuery('.contact-message-form-newsletter-form #edit-field-email-0-value').val(jQuery('#footerInputNewsletter').val());
    });

    jQuery('#footerSendNewsletter').click(function() {
        jQuery('.contact-message-form-newsletter-form #edit-submit').trigger('click');
    });


    jQuery('.js-scrollTo').on('click', function(e) {
        e.preventDefault();
        var ancre = $(this).attr('href');
        jQuery('html, body').animate( { scrollTop: $(ancre).offset().top }, 500 );
        return false;
    });

    //------------------------------------------
    // Ancres sous menu
    //------------------------------------------

    var jSubTitle = $('.wysiwyg-titre'),
      jSubMenu  = $('.SubMenu-container'),
      $currentItem = $(".isActive", jSubMenu),
      $anchorContainer = $('<div class="container-ancre"></div>')

    ;

    $('.wysiwyg').find('.wysiwyg-titre').each(function(index){
        let text = $(this).text(),
            id = 'item-' + index,
            link = `<a href="#${id}" class="item-ancre">${text}</a>`
        ;
        $(this).attr('id', id);
        $anchorContainer.append(link);
    });
    $currentItem.append($anchorContainer);

    //------------------------------------------
    // ScrollTo
    //------------------------------------------

    var SubMenuAncre = $('.item-ancre');

    SubMenuAncre.on("click", function(){
        var $this = $(this),
        href = $this.attr("href"),
        topY = $(href).offset().top;

        TweenLite.to($(window), 1, {
            scrollTo:{
                y: topY,
                autokill: true
            },
            ease: Power4.easeOut
        });
        return false;
    });


    //------------------------------------------
    // Scroll du sous-menu
    //------------------------------------------


    var jSubMenuScroll = $('.SubMenu-container'),
    jSubMenu = $('.SubMenu'),
    jSubMenuH = jSubMenu.height(),
    $jFooter = $('.SiteFooter');

    if (!!jSubMenuScroll.offset()) {
        var jSubMenuScrollHeight = jSubMenuScroll.height(),
          jSubMenuTop = jSubMenuScroll.offset().top,
          jSubMenuOffset = 0,
          jFooterPosition = $jFooter.offset().top,
          stopPoint = jFooterPosition - jSubMenuScrollHeight - jSubMenuOffset,
          width = $(".SubMenu").outerWidth()
        ;

        // console.log();
        $(window).scroll(function(){

            // console.log($('.colMenuViolet').height());
            var windowTop = $(window).scrollTop();
            // console.log(windowTop);

            if (windowTop+jSubMenuOffset > jSubMenuTop && windowTop+jSubMenuOffset < jSubMenuTop + jSubMenuH - jSubMenuScrollHeight) {
                jSubMenuScroll.css({ position: 'fixed', top: jSubMenuOffset, width:width});
            }else if(windowTop+jSubMenuOffset > jSubMenuTop + jSubMenuH - jSubMenuScrollHeight){
                jSubMenuScroll.css({ position: 'absolute', bottom: '20px', top:'inherit',width:width});
            }else {
                jSubMenuScroll.css({position: 'absolute', top: 'initial',bottom:'inherit', width: "auto"});
            }
        });
    }



    // Home masonry
    let $offres = $('.grid')
    if($offres.length){
        var elem = document.querySelector('.grid');
            var msnry = new Masonry( elem, {
              // options
              itemSelector: '.grid-item',
        });
    }

});
